
//자각증상20 시작
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Box, Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ReactAudioPlayer from "react-audio-player";
import DialogActions from "@material-ui/core/DialogActions";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useCookies} from "react-cookie";

export function SurveyPop(props){

    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [cookie, setCookie] = useCookies()
    const [useAudio , setAudio] = React.useState(!('yes' === cookie._sana))
    // 'yes' === _sana 오디오를 설명을 사용하지 않습니다. 
    const handleClose = () => {
        setOpen(false);
        if(props.onStart) props.onStart()
    };

    let text = '';
    switch (props.surveyType){
        case 'emotion':
            text = <Box><b>감정자각증상 검사를 시작하겠습니다.</b><br/><br/>각 항목마다 증상 정도를 10%에서~100%까지 클릭하세요. <br/>증상이 없으면 표기하지 마시고 다음 항목으로 넘어가세요. <br/><br/>검사를 다 마치면 검사 완료를 클릭하신 후 <br/>제출완료를 클릭해주세요.</Box>;
            break

        case 'health':
            text = <Box><b>운동자각증상 검사를 시작하겠습니다.</b><br/><br/>각 항목마다 증상 정도를 10%에서~100%까지 클릭하세요. <br/>증상이 없으면 표기하지 마시고 다음 항목으로 넘어가세요. <br/><br/>검사를 다 마치면 검사 완료를 클릭하신 후 <br/>제출완료를 클릭해주세요. </Box>;
            break

        case 'life-cycle':
            text = <Box><b>라이프 사이클 검사를 시작합니다.</b><br/><br/>연령 5년 주기로 삶의 행복 정도를 <br/>10에서 ~ 50까지 클릭해주세요. <br/><br/>너무 힘들고 어려웠다면 <br/>-10에서~ -50까지 클릭해주세요. <br/><br/>검사를 다 마치면 검사 완료를 클릭하신 후 <br/>제출완료를 클릭해주세요.</Box>;
            break

        case 'life':
            text = <Box><b>생활습관 검사를 시작하겠습니다.</b><br/><br/>바르고 건강한 생활을 하는 정도를 100~10까지 표기하세요.<br/><br/>검사를 다 마치면 검사 완료를 클릭하신 후 <br/>제출완료를 클릭해주세요.</Box>;
            break

        case 'recognition':
            text = <Box><b>인지기능 검사를 시작하겠습니다.</b><br/><br/>항목별 기능장애가 없으면 –"아니다" <br/>항목별 기능장애가 가끔 있으면 -"가끔 그렇다"<br/>항목별 기능장애가 자주 발생하면 -"자주 그렇다"를 표기하세요.<br/><br/>검사를 다 마치면 검사 완료를 클릭하신 후<br/>제출완료를 클릭해주세요.</Box>;
            break
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle className="common-dialog-title">{props.subject}</DialogTitle>
                <DialogContent className={'common-dialog-content'} >
                    {text}
                    <br/>
                    <br/>

                    <ReactAudioPlayer
                        autoPlay={useAudio}
                        src={require(`@audio/${props.surveyType}-slow.mp3`)}
                        controls
                        onEnded={()=>{
                            setTimeout(() => {
                                setOpen(false)    
                            }, 1000)
                        }}
                    />
                    <br/>
                    <br/>
                    <input type="checkbox" 
                           checked={!useAudio} id="chk" style={{width:20, height:20}}
                           onChange={(event,checked) => {
                               const status = !useAudio
                               setAudio(status)
                               setCookie('_sana', status ? 'no' : 'yes')
                           }}
                    /><label form={'chk'}>다음부터 음성안내를 사용하지 않습니다 </label>
                </DialogContent>
                <DialogActions className={'common-dialog-action'}>
                    <Button
                        onClick={handleClose}
                        fullWidth
                        endIcon={<ArrowForwardIosIcon />}
                    >
                        시작하기
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
