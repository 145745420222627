
export default class PageService {
    
    static pageMeta(serviceType){
        let meta
        switch (serviceType){
            case 'app:measureTest':
                meta = {
                    title: '뇌기능검사',
                    image: '',
                    url: '',
                    favicon16: '/favicon/measureTest/favicon-16x16.png',
                    favicon32: '/favicon/measureTest/favicon-32x32.png',
                    favicon96: '/favicon/measureTest/favicon-96x96.png'
                }
                break
            case 'app:measureTrain':
                meta = {
                    title: '두뇌계발',
                    image: '',
                    url: '',
                    favicon16: '/favicon/measureTrain/favicon-16x16.png',
                    favicon32: '/favicon/measureTrain/favicon-32x32.png',
                    favicon96: '/favicon/measureTrain/favicon-96x96.png'
                }
                break
            case 'app:survey':
                meta = {
                    title: '생활습관교정',
                    image: '',
                    url: '',
                    favicon16: '/favicon/survey/favicon-16x16.png',
                    favicon32: '/favicon/survey/favicon-32x32.png',
                    favicon96: '/favicon/survey/favicon-96x96.png'
                }
                break
            case 'app:survey-life':
                meta = {
                    title: '생활습관',
                    image: '',
                    url: '',
                    favicon16: '/favicon/surveyLife/favicon-16x16.png',
                    favicon32: '/favicon/surveyLife/favicon-32x32.png',
                    favicon96: '/favicon/surveyLife/favicon-96x96.png'
                }
                break
            default:
                meta = {
                    title: '브레인헬스',
                    image: '',
                    url: '',
                    favicon16: '/favicon/web/favicon-16x16.png',
                    favicon32: '/favicon/web/favicon-32x32.png',
                    favicon96: '/favicon/web/favicon-96x96.png'
                    
                }
        }
            
        return meta
    }
}