import React, {useCallback, useEffect, useState} from 'react';

import {Box, Button, Grid, Tab, Tabs} from '@material-ui/core';
import ExerciseBox from '@user-pages/exercise/ExerciseBox'
import Typography from "@material-ui/core/Typography";
import FirstMeasureMessage from "../../../components/FirstMeasureMessage";
import NeedTicketDialog from "../../../components/NeedTicketDialog";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {makeStyles} from "@material-ui/core/styles";
import MeasureTestHistoryDialog from "./MeasureTestHistoryDialog";
import clsx from "clsx";
import StarIcon from "@material-ui/icons/Star";
import HeatMap from "react-heatmap-grid";
import ColorHelper from '@helper/ColorHelper';

import SurveyService from '@user-service/SurveyService'
import MeasureService from '@user-service/MeasureService'
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const MeasureTestDefault = (props) => {
    const [open, setOpen] = useState(false)
    
    const { state, measureType } = props
    const [ showHistory, setHistory ] = useState(false)
    const { exercise, prescription, measureTypesCount } = state
    const classes = useStyles()
    const [ currentSelect, setCurrentSelect ] = useState(0)
  
    const handleTab = (select) => {
      setCurrentSelect(select)
    }

    
  const [survey, setSurvey] = useState(null)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

  const fetchHSurveyHistory = useCallback(async () => {
    const res = await MeasureService.getMeasureTestYear(currentYear);
    
    
    setSurvey(res)
  }, [currentYear, props.surveyType])

  useEffect(()=>{
    fetchHSurveyHistory().then()
  },[fetchHSurveyHistory])

  
  return (
        <div id="cont-wrap">
            <div className={clsx('sv',{
                     'green' : ['develop','train'].includes(measureType)
                 })}>
                <div>
                    <h3>
                        {['develop','train'].includes(measureType) ? '두뇌 계발' : '뇌기능 검사'}
                    </h3>
                    <span>기본적인 항목을 검사 합니다.</span>
                    <i>Brain Health</i>
                </div>
            </div>

            <div className="cont">
                <Box mb={3}>
                    <Grid container className={'tabs'}>
                      <Grid item xs={8}>
                        {measureType === 'measure' &&
                        <MenuButtons onUpdate={(select) => handleTab(select)}/>
                        }
                      </Grid>
                      
                      <Grid item xs={4}>
                        <Box display={'flex'} justifyContent={'flex-end'} width={'100%'} style={{marginBottom: 20, paddingRight: 10}}>
                          <Button
                            className={classes.button}
                            endIcon={<ChevronRightIcon className={'rightIcon'}/>}
                            onClick={async () => {
                              setHistory(!showHistory)
                            }}
                          > 기록보기 </Button>
                        </Box>
                      </Grid>
                    </Grid>
                </Box>

                {!prescription &&
                    <FirstMeasureMessage />    
                }
                {prescription &&
                <>
                  { currentSelect === 0 &&
                    <Grid container spacing={3}>
                        {exercise.map((ex) => {
                            return (
                                <Grid item  md={4} xs={6} key={ex.id}>
                                    <ExerciseBox
                                        {...ex}
                                        history={props.history}
                                        prescriptionID={prescription.id}
                                        measureTypesCount={measureTypesCount.find(type => type.key === ex.programID)}
                                        onNeedTicket={() => {
                                            setOpen(true)
                                        }}
                                        measureType={measureType}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                  }
                  { currentSelect === 1 &&
                    <Box style={{border: '2px solid #ddd'}}>
                      <Grid container justify="center" className={classes.wrap}>
                        <Grid item>
                          <Button
                            className={clsx('year', 'sideLeft')}
                            startIcon={<KeyboardArrowLeftIcon />}
                            onClick={()=>{
                              setCurrentYear(currentYear-1)
                            }}>
                            {currentYear-1}년
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button className={'year'}>
                            {currentYear}년
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button className={clsx('year', 'sideRight')}
                                  endIcon={<KeyboardArrowRightIcon />}
                                  onClick={()=>{
                                    setCurrentYear(currentYear+1)
                                  }}>
                            {currentYear+1}년
                          </Button>
                        </Grid>
                      </Grid>
                      
                      <div style={{padding: 62}}>
                        <MeasureTestHeatMap
                          items={survey.items}
                          startIndex={0}
                          currentYear={currentYear}
                          useTerm={false}
                          recent={props.recent}
                          surveyType={props.surveyType}
                        />
                      </div>
                    </Box>
                  }
                </>
                }
            </div>
            <NeedTicketDialog
                open={open} 
                onClose={(() => setOpen(false))}
            />
            <MeasureTestHistoryDialog
                exercise={exercise}
                open={showHistory}
                onClose={() => {
                    setHistory(false)
                }}
            />
        </div>
    )
}


export function MenuButtons(props){
  const classes = useStyles()
  const buttons = [
    {
      title : '뇌기능 검사',
    },
    {
      title : '검사 결과',
    },
  ]
  const [showType, setShowType] = useState(0)

  return (
    <Tabs
      value={showType}
      onChange={(event, newValue) => {
        setShowType(newValue)
        if (props.onUpdate) {
          props.onUpdate(newValue)
        }
      }}
      indicatorColor="primary"
      textColor="primary"
    >
      {buttons.map((button,index) => {
        return (
          <Tab key={index}
               className={'tab'}
               label={<span
                 className={classes.label}>
                             {button.title}
                         </span>} />
        )
      })}
    </Tabs>
  )
}



export  function MeasureTestHeatMap(props) {
  const heatmapColor = ColorHelper.getSurveyColors()
  const top5OrderIndex = []
  const classes = useStyles();

  if(props.recent && 0 < props.recent.items.length){
    let item
    for(let i = 0 ; i < 5; i++){
      item = props.recent.items[i]
      if(0 < item.level){
        top5OrderIndex.push(item.order)
      }
    }
  }

  let range = 12
  let startMonth = 1
  if(props.useTerm){
    const term = 1 !== props.currentTerm ? props.currentTerm - 1 : 0
    startMonth = ( term * range) + 1
  }

  const xLabels = new Array(range).fill(0).map((_, i) => `${i+startMonth}월`);
  const xLabelsVisibility = new Array(range).fill(false)

  const yLabels = props.items.map((item, index) => {
    const title = `${index + 1 + props.startIndex}. ${item.content}`
    const isCheck = -1 < top5OrderIndex.indexOf(index + 1)
    return (
      <Box position={'relative'} className={'mapTitle'}>
        <Typography
          variant={'body1'}
          className={clsx(classes.wrapIcon,{
            [classes.check] : isCheck
          })}>
          {title}
          {isCheck && <StarIcon className={classes.check}/> }
        </Typography>

      </Box>
    )
  })

  const data = new Array(yLabels.length).fill(0).map((_, rowIndex) =>
    new Array(xLabels.length).fill(0).map((_, columnIndex) => {
      const rounds = props.items[rowIndex].rounds
      if(rounds){
        const key = props.currentYear + '-' + ('00' + (columnIndex + startMonth)).substr(-2)
        const surveyAnswer = rounds[key]
        
        if(surveyAnswer && 0 < surveyAnswer.length){
          return surveyAnswer[0].symptomLevel
        } else {
          return null
        }
      }

      return _
    })
  )

  yLabels.unshift('')
  data.unshift(xLabels)

  return (
    <HeatMap
      className={classes.map}
      xLabels={xLabels}
      yLabels={yLabels}
      xLabelsLocation={"bottom"}
      xLabelsVisibility={xLabelsVisibility}
      yLabelWidth={550}
      yLabelTextAlign={'left'}
      data={data}
      squares
      cellStyle={(background, value, min, max) => {
        let newBackground = `rgba(123, 201, 110, ${1 - (max - value) / (max - min)})`
        let color = '#333'

        if(!value && 0 !== value) {
          newBackground = '#f3f3f3'
          color = `#ddd`
        } else {
          newBackground = heatmapColor[(value / 10)]
          if( 0 === value ) {
            color = '#999'
            newBackground = '#efefef'
          } else if ( 60 < value ){
            color = '#fff'
          }
        }
        return {
          background: newBackground,
          color,
          fontWeight: '900',
          fontSize: 13,
          width: '50px',
          height: '40px',
          lineHeight: '28px',
        }
      }}
      cellRender={ (value) => {
        let _value = value
        if('life' === props.surveyType && value && !isNaN(_value)){
          _value = 100 - Number(value) + 10
        }

        return (
          <Box>
            {_value}
          </Box>
        )
      }}
      title={(value) => `${value}`}
    />
  )
}


const useStyles = makeStyles(() => ({
    label: {
      fontSize:24,
      fontWeight: 'bold',
      colo: '#333'
    },
    wrapIcon: {
      verticalAlign: 'middle',
      display: 'inline-flex'
    },
    check: {
      color: '#FF843A'
    },
    map: {
      '& .mapTitle': {
        backgroundColor: '#efefef'
      }
    },
    wrap: {
      backgroundColor: '#0054BF',
      '& .year': {
        fontSize: 32,
        fontWeight: 'bold',
        color: 'white'
      },
      '& .sideLeft': {
        color: '#CCCCCC',
        paddingRight: 60,
        '& .MuiSvgIcon-root': {
          fontSize: 30
        }
      },
      '& .sideRight': {
        color: '#CCCCCC',
        paddingLeft: 60,
        '& .MuiSvgIcon-root': {
          fontSize: 30
        }
      }
    },
    button: {
        border: '2px solid #BEBEBE',
        padding: '5px 30px',
        borderRadius: 20,
        color: '#666',
        '& .MuiButton-endIcon': {
            marginRight: -15
        },
        '& .rightIcon': {
            color: '#00DD94',
            fontSize: 25,
            fontWeight: 'bold'
        }
    },
}));


export default MeasureTestDefault
