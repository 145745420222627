
const INITIAL_STATE = {
    menu: [
        {
            id: 1,
            name : '통합관리',
            url: '/dashboard',
            role: ['admin','normal','customer'],
            active: false,
            profileSelectView: true
        },
        {
            id: 3,
            name : '뇌기능측정',
            url: '/measureSelect',
            role: ['admin','normal'],
            active: false,
            profileSelectView: true
        },
        {
            id: 4,
            name : '뇌기능측정',
            url: '/manage/requestPrescript',
            role: ['customer'],
            active: false,
            profileSelectView: false
        },
        {
            id: 5,
            name : '뇌기능분석',
            url: '/measureCurrent/last',
            role: ['admin','customer','normal'],
            active: false,
            profileSelectView: false
        },
        {
            id: 6,
            name : '뇌특성분석',
            url: '/measureBalanceCurrent/last',
            role: ['admin','customer','normal'],
            active: false,
            profileSelectView: false
        },
        {
            id: 7,
            name : '생활습관교정',
            url: '/survey-year',
            role: ['admin','customer'],
            active: false,
            profileSelectView: false
        },
        {
          id: 77,
          name : '자각증상 & 생활습관',
          url: '/survey-year',
          role: [],
          active: false,
          profileSelectView: true
        },
        {
          id: 2,
          name : '뇌기능검사',
          url: '/measureTest',
          role: ['admin','customer'],
          active: false,
          profileSelectView: true
        },
        {
          id: 2,
          name : '두뇌계발',
          url: '/measureTrain',
          role: ['admin'],
          active: false,
          profileSelectView: true
        },
        {
          id: 78,
          name : '측정결과-P',
          url: '/measureCurrent/last',
          role: [],
          active: false,
          profileSelectView: true
        },
        {
            id: 8,
            name : 'BRHW',
            url: '/brainhealth',
            role: ['normal', 'admin', 'customer'],
            active: false,
            profileSelectView: true
        },
        // {
        //     id: 9,
        //     name : '측정기록 ',
        //     url: '/measureSchedule',
        //     role: ['normal', 'admin'],
        //     active: false,
        //     profileSelectView: false
        // },
        {
            id: 10,
            name : '기록',
            url: '/logs',
            role: ['normal', 'admin', 'customer'],
            active: false,
            profileSelectView: true
        },
        // {
        //     name : '메세지',
        //     url: '/message',
        //     active: false
        // },
        // {
        //     name : '고객센터',
        //     url: '/helpdesk',
        //     active: false
        // }
    ]
  }

const UserAppReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
      case 'ACTIVE_MENU':

        let updateMenu = {...state.menu}
        for(let i = 0; i < updateMenu.length; i++){
            if (action.url === updateMenu[i].url) {
                updateMenu[i].active = true
            } else {
                updateMenu[i].active = false
            }
        }

        return {
            ...state,
            menu: updateMenu
        }
        
      default:
        return state
    }
  }
  
export default UserAppReducer
