import React, {useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import DeviceService from '@user-service/DeviceService';
import SocketProvider from '@user-provider/SocketProvider';
import DeviceHelper from "@helper/DeviceHelper";
import BridgeProvider from "../../provider/BridgeProvider";

const DeviceWaiting = () => {    
    const classes = useStyles()

    const useInterval = (callback, delay) => {
        const savedCallback = useRef();

        useEffect(() => {
            savedCallback.current = callback;
        });

        useEffect(() => {
            const tick = () => {
                savedCallback.current();
            }

            const timerId = setInterval(tick, delay);
            return () => clearInterval(timerId);
        }, [delay]);
    }
    
    useEffect(() => {
        BridgeProvider.instance.closeLoading()
    }, [])
    
    
    useEffect(()=>{
        window.app = {
            connectLocalDevice: connectLocalDevice.bind(this),
            serialportLog: serialportLog.bind(this),
        };
        window.app.onVersion = (version) => { BridgeProvider.instance.resolve(version) }

        const configFetch = async () => {
            const res  = await DeviceService.getServerVersion()
            return res.version
        }
        
        configFetch().then((severVersion) => {
            setTimeout( async () => {
                const pcVersion = await BridgeProvider.instance.getVersion() + ''

                const rPcVersion = parseInt(pcVersion.replace(/\./ig, '' ))
                const rServerVersion = parseInt(severVersion.replace(/\./ig,''))
                
                if(rPcVersion < rServerVersion && 'desktop' === BridgeProvider.type) {
                    alert('브레인헬스 새로운 버전이 있습니다. (' + severVersion + ')\n 다운로드 후 재시작 합니다.');
                    await BridgeProvider.instance.updateApp('https://cli.brhw.kr/api/update/check')
                } else {
                    await BridgeProvider.instance.initSocketWithSerialport()
                }
            }, 500)    
        })
    },[])
    
    useInterval(() => {
        BridgeProvider.instance.toReady()
        console.log('to ready')
    }, 3000)
    
    const connectLocalDevice = async (computerMac, computerName, roomId, deviceSerial) =>{
        await SocketProvider.deviceMeasureStop()
        document.location.href = `/login-device?computerMac=${computerMac}&computerName=${computerName}&roomID=${roomId}&deviceSerial=${deviceSerial}`
    }

    const handleExit = async () => {
        await DeviceHelper.exitProgram()
    }
    const serialportLog = (message) => {
        SocketProvider.log('Device Waiting', message)
    }

    return (
        <Box className={classes.root}>
          <Box className={classes.text}>
            브레인 헬스를 시작하고 있습니다 <br/>
            잠시만 기다려 주세요 <br/>
            <span style={{fontSize: '1.1rem', letterSpacing: '0.5px', color: '#aaa'}}>브레인 헬스 기기와 연결을 확인해주세요</span>
          </Box>
          <div className={classes.wrap} />
            <div className={classes.videoWrap}>
                <video src={'https://d2iajcsshqwtw3.cloudfront.net/assets/loginBackground.webm'}
                       poster={'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/loginBackground.png'}
                       autoPlay
                       loop
                       muted
                       style={{width: '100%'}}
                />
            </div>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    subTitle: {
        fontSize: 22
    },
    root: {
        overflow: 'hidden'
    },
    text: {
        position: 'absolute',
        left: 0,
        top: window.innerHeight / 2 - 100 ,
        zIndex: 3,
        color: 'white',
        width: '100%',
        textAlign: 'center',
        fontSize: 40,
        fontWeight: 500,
        marginBottom: 0,
        letterSpacing: -3
    },
    wrap : {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: window.innerHeight,
        background: '#000',
        zIndex: 1,
        opacity: 0.7,
    },
    videoWrap: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: window.innerHeight,
        background: '#100d14',
        zIndex: 0
    }
}));

export default DeviceWaiting

