import React, {useState, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import AuthProvider from "@user-provider/AuthProvider";
import AudioPlayer from '@user-pages/exercise/common/AudioPlayer'
import { Box, Grid} from '@material-ui/core';

import Intro from '@user-pages/exercise/common/Intro'
import SocketProvider from '@user-provider/SocketProvider';
import ExerciseService from '@user-service/ExerciseService';

import LevelDialog from "./LevelDialog";
import CompleteDialog from "./CompleteDialog"  //뇌파측정 - 균형상태 상새보기
import RewardDialog from "./RewardDialog"
import MeasureHelper from '@helper/MeasureHelper'
import ExerciseStatusBar from "../ExerciseStatusBar";
import Hexagon from "react-hexagon";
const { measureTypeToCode } = MeasureHelper
const MeasureHzType = 'memoryEgg'

const MemoryEgg = (props) => {
    const stageRef = useRef()
    const [status, setStatus] = useState('intro')
    
    useEffect(()=>{
        SocketProvider.connect(()=>{}, window, MeasureHzType)
        return () => {
            SocketProvider.measureStop(true).then()
        }
    },[])

    const onNext = () => {
        setStatus('stage')
    }
    
    return (
        <Box style={{position: 'relative', overflow:'hidden'}}>
            
            <Intro 
                onNext={onNext.bind(this)} 
                poster={'https://d2iajcsshqwtw3.cloudfront.net/assets/poster/memoryEggIntro.png'}
                src={'https://d2iajcsshqwtw3.cloudfront.net/assets/memoryEggIntro.webm'}
                onPlay={() => {
                    setStatus('stage')
                }}
            />
            
            {'stage' === status &&
            <Stage ref={stageRef} 
                   history={props.history}
            />
            }
        </Box>
    );
}


export class Stage extends React.Component {
    container
    clientRect

    itemWidth = 176
    itemHeight = 190
    isReverse = false
    useReverse = false

    audioRef = React.createRef()
    eggRef = React.createRef()
    levelDialogRef = React.createRef()
    completeDialogRef = React.createRef()
    rewardDialogRef = React.createRef()
    scoreRef = React.createRef()
    
    runCount = 0                //전체 실행 수
    currentRunCount = 1         //현재 실행수
    correctEggCount = 0         //맞춘횟수 
    eggCount = 2                // 처음 시작 카운트
    currentEggCount = 0         // 현재 에그 노출 수 

    currentEggs = []
    selectedEggs = []
    correctEggs = []

    audioPlayStatus= 'start'
    showState = 'hide'
    
    hideTimer;
    
    currentMeasureType = 'rest'
    currentMeasureCode = 'AE'
    currentIntervalTime = 0
    
    defaultShowPositionOrder = [
        {x: 1,y: 0}, {x: 2,y: 0}, {x: 0, y: 0},
        {x: 1,y: 1}, {x: 0,y: 1}, {x: 2, y: 1},
        {x: 0,y: 2}, {x: 1,y: 2}, {x: 2, y: 2},
    ] 

    constructor(props) {
        super(props)

        const eachSectorWidth =  Math.floor(window.innerWidth / 3);
        const defaultOpacity = 0
        this.state = {
            items: [1,2,3,4,5,6,7,8,9,10],
            position:[
                [
                    { bottom: 130, left: eachSectorWidth * 0.7, width: 300, height:300, blur: '2px', opacity: defaultOpacity, image: require(`@assets/exercise/memoryEgg/che1.png`)},
                    { bottom: 120, left: eachSectorWidth * 1.1, width: 300, height:300, blur: '2px', opacity: defaultOpacity, image: require(`@assets/exercise/memoryEgg/che4.png`)},
                    { bottom: 160, left: eachSectorWidth + 1.0, width: 300, height:300, blur: '2px', opacity: defaultOpacity, image: require(`@assets/exercise/memoryEgg/che7.png`)},
                ],
                [
                    { bottom: 50, left: eachSectorWidth * 0.03 , width: 300, height:300, blur: '1px', opacity: defaultOpacity, image: require(`@assets/exercise/memoryEgg/che2.png`)},
                    { bottom: 30, left: eachSectorWidth * 0.5 , width: 300, height:300, blur: '1px', opacity: defaultOpacity, image: require(`@assets/exercise/memoryEgg/che5.png`)},
                    { bottom: 20, left: eachSectorWidth * 1.4 , width: 300, height:300, blur: '1px', opacity: defaultOpacity, image: require(`@assets/exercise/memoryEgg/che8.png`)},
                        
                ],
                [
                    { bottom: -50, left: eachSectorWidth * 0.5 , width: 300, height:300, blur: '0px', opacity: defaultOpacity, image: require(`@assets/exercise/memoryEgg/che3.png`)},  
                    { bottom: -30, left: eachSectorWidth * 0.9 , width: 300, height:300, blur: '0px', opacity: defaultOpacity, image: require(`@assets/exercise/memoryEgg/che1.png`)},
                    { bottom: -50, left: eachSectorWidth * 0.9 , width: 300, height:300, blur: '0px', opacity: defaultOpacity, image: require(`@assets/exercise/memoryEgg/che6.png`)},
                ],
                []
            ],
            showPositionOrder: this.getEggPosition(),
            background:[
                require('@assets/exercise/memoryEgg/background4.png'),
                require('@assets/exercise/memoryEgg/background3.png'),
                require('@assets/exercise/memoryEgg/background2.png'),
                require('@assets/exercise/memoryEgg/background1.png'),
            ],
            history: props.history,
            tempCount: 1 ,
            showAnswerBox: false
        }
    }
    
    componentDidMount() {
        this.container = ReactDOM.findDOMNode(this)
        this.clientRect = this.container.getClientRects()[0]
        this.audioRef.current.loadEffect(MeasureHzType)

        
        SocketProvider.onMeasure = (data) => {
            // debug
            try {

                if(data.isHit && 0 === this.selectedEggs.length){
                    if(this.hideTimer){
                        return
                    }

                    if(this.currentEggCount === this.eggCount){
                        return
                    }

                    if('hide' === this.showState){
                        this.showState = 'show'
                        this.play()
                    }

                    const delayTimeout = 0.5 * data.hitCount * 1000
                    this.hideTimer = setTimeout(() => {
                        this.hideTimer = null
                        this.showState = 'hide'
                        this.clearEgg()
                        
                        if(!this.isShowPlaying()){
                            setTimeout(() => {
                                this.setState({
                                    ...this.state,
                                    showAnswerBox: true
                                })
                            }, 300)
                        }
                    }, delayTimeout)
                }
            } catch (e){
                console.log(e)
            }
        }
    }
    
    getEggPosition(){
        return JSON.parse(JSON.stringify(this.defaultShowPositionOrder))
            .map(a => ([Math.random(),a]))
            .sort((a,b) => a[0]-b[0])
            .map(a => a[1])
    }
    
    clearEgg(){
        try {
            this.eggRef.current.style.backgroundImage = ''    
        } catch(e) {}
        
    }
    
    refresh(){
        this.setState({
            ...this.state,
            showAnswerBox: this.isReverse
        })
    }

    run(runCount) {  
        this.runCount = runCount
        this.startSoundWithPlay()
    }


    startSoundWithPlay(){
        this.audioPlayStatus = 'start'
        this.audioRef.current.effect('start')
        
        this.refresh()

    }

    startReverseSoundWithPlay(){
        this.audioPlayStatus = 'reverse'

        this.audioRef.current.effect('start')
        this.selectedEggs = []
        this.refresh()
    }
    setAudioMode(measureType){
        this.audioRef.current.setMode(measureType)
    }
    
    endSound(){
        this.audioRef.current.effect('stop')
    }

    play(){
        this.audioPlayStatus = 'play'
        this.audioRef.current.effect('show')
 
        let shuffled = [0,1,2,3,4,5,6,7,8,9]
            .filter((value) => {
                return !(-1 < this.currentEggs.indexOf(value))
            })
            .map(a => ([Math.random(),a]))
            .sort((a,b) => a[0]-b[0])
            .map(a => a[1])

        // const index = parseInt(Math.random() * 10)
        const index = shuffled[0]
        const image = require(`@assets/exercise/memoryEgg/${index + 1}.png`)

        this.eggRef.current.style.backgroundImage =  `url(${image})`
        this.currentEggs.push(index)
        this.currentEggCount++
        
        this.setState({
            ...this.state
        })
     
        
    }
    
    onAudioEnded() {
        return false
    }

    eggClick(index) {
        if(this.isShowPlaying()) return false

        //clear showing egg 
        this.selectedEggs.push(index)

        // 노출된 개수를 클릭했을때 처리 
        if(this.currentEggCount === this.selectedEggs.length){
            this.checkGameState()
        } 

        this.setState({
            ...this.state
        })
    }
    
    isShowPlaying(){
        // console.log('this.currentEggCount', this.currentEggCount, 'this.eggCount', this.eggCount)
        //시작 안할경우 또는 랜덤 수가 모두 표시되지 않았을경우
        if(0 === this.currentEggCount || this.eggCount !== this.currentEggCount) return true
        
        //에그 보이기 진행경우
        return '' !== this.eggRef.current.style.backgroundImage;
    }
    
    checkGameState(){
        // 정답 처리 
        const isCorrect = this.isCorrectAnswer()
        let score = 0;
        if(isCorrect){
            if(this.useReverse){
                // 한 스테이지당 맞춤과 거꾸로 맞춤을 처리해야함 ㅁ
                if(!this.isReverse){
                    setTimeout(() => {
                        this.isReverse = true;
                        this.startReverseSoundWithPlay();
                    }, 1000);
                    return
                }
            }
            this.addCorrect()
            this.correctEggCount++

            score = 10
            if(1 === this.correctEggCount){
                score = 20
            }
        }

        this.scoreRef.current.update(score, !(this.currentRunCount === this.runCount))
        
        if(5 === this.currentEggCount && isCorrect){
            this.rewardDialogRef.current.open(() => {
                setTimeout(()=> {
                    this.resetAndIncreaseCount(isCorrect)
                    this.startSoundWithPlay();
                }, 1000)
            })

            this.currentRunCount++
            return
        }
        
        //최대치 도달
        
        if(this.currentRunCount === this.runCount){
            // 최대 10개 다 맞추고 종료시 
            if(this.correctEggCount === this.runCount && isCorrect){
                this.rewardDialogRef.current.open(() => {
                    this.stopExercise()
                })    
            } else {
                this.stopExercise()
            }
            
        } else {
            setTimeout(()=> {
                this.resetAndIncreaseCount(isCorrect)
                this.startSoundWithPlay();
            }, 1000)
        }

        this.currentRunCount++
    }

    addCorrect(refresh = true){
        const  index = this.state.showPositionOrder.shift()
        const position = this.state.position
        position[index.x][index.y].opacity = 1
        
        if(refresh){
            this.setState({
                ...this.state,
                position,
            })
        }
    }

    /**
     * 사용자 정답 체크
     */
    isCorrectAnswer(){
        let eggs;

        if(this.isReverse){
            eggs = Object.assign([],this.selectedEggs).reverse() ;
        } else {
            eggs = this.selectedEggs;
        } 

        for(let i = 0; i < eggs.length; i++){
            if(eggs[i] !== this.currentEggs[i]){
                return false
            }
        }
        
        return true
    }

    /**
     * 현재 스테이지를 초기화
     */
    roundReset(){
        this.isReverse = false
        this.selectedEggs = []
        this.currentEggs = []
        this.currentEggCount = 0
    }
    /**
     * rest 후 egg 노출 횟수를 증가합니다
     */
    resetAndIncreaseCount(isCorrect){
        this.roundReset()
        if(isCorrect){
            this.eggCount++
        }
        setTimeout(() => {
            this.showState = 'hide'    
        },700)
    }
    
    clearStage() {
        this.roundReset()
        this.eggCount = 2
        this.currentEggs = []
        this.correctEggCount = 0

        const position = this.state.position
        for(let i = 0 ; i < position.length; i++){
            for(let j = 0 ; j < position[i].length; j++){
                position[i][j].opacity = 0
            }
        }
        
        this.setState({
            ...this.state,
            position,
            showPositionOrder: this.getEggPosition(),
            tempCount: this.state.tempCount + 1
        })
    }
    
    async startExercise(useReverse) {
        this.clearEgg()
        this.clearStage()

        this.useReverse = useReverse
        const intervalTime = this.currentIntervalTime ? this.currentIntervalTime : 60 * 60;
        await SocketProvider.measureStart(intervalTime,0,MeasureHzType, this.currentMeasureType, measureTypeToCode(this.currentMeasureType), this.currentMeasureCode)
        this.setAudioMode(this.currentMeasureType)
        this.run(9) // 총 10개를 표시하지만 시작이 2개부터라 9라운드 처리
    }

    stopExercise(playSound = true) {
        if(playSound){
            this.audioRef.current.effect('stop')       
        }
        SocketProvider.measureStop().then()
    }

    render() {
        const imEggCount = new Array(this.eggCount).fill(0)
        let imCurrentEggs

        if (this.isReverse) {
            imCurrentEggs = Object.assign([], this.currentEggs).reverse()
        } else {
            imCurrentEggs = this.currentEggs
        }
        
        return ( 
            <Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    position="absolute"
                    right={40}
                    top={90}
                    zIndex={20}
                    className={'concentration'}
                >
                    <Score ref={this.scoreRef}/>
                </Box>
                
                <ExerciseStatusBar
                    measureHzType={MeasureHzType}
                    measureType={this.currentMeasureType}
                    measureCode={this.currentMeasureCode}
                    hideIntervalTime={true}
                    onResultVar={ async ({measureLogID, measureHzType}) => {
                        const json = {
                            score : this.scoreRef.current.getScore()
                        }
                        
                        await ExerciseService.setResultVar({
                            measureLogID,
                            measureHzType,
                            resultVar: JSON.stringify(json)
                        })
                    }}
                    close={async () => {
                        this.stopExercise(false, true)
                    }}
                    start={async (runningTime, measureType, measureCode) => {
                        this.currentMeasureType = measureType
                        this.currentMeasureCode = measureCode
                        this.currentIntervalTime = runningTime
                      
                        this.levelDialogRef.current.open()
                    }}
                />

                
                <Grid container style={{width: '100%'}}>
                    <Grid item xs={12} style={{ position: 'relative', height: window.innerHeight}}>
                        {/*백그라운드*/}
                        <Box 
                            style={{height: 1000}}>
                            {this.state.background.map((background, index) => {
                                return (
                                    <Box key={index}
                                        style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        zIndex: 1, 
                                        height: '100%',
                                        width: '100%',
                                        backgroundImage: `url(${background})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}>
                                        <Box style={{width: '100%', height: '100%', position: 'relative'}}>
                                            {this.state.position[index].map((chic, cheIndex) => {
                                                return (
                                                    <Box position="absolute"
                                                         left={chic.left + (cheIndex * 300)}
                                                         bottom={chic.bottom}
                                                         zIndex="3"
                                                         key={cheIndex}
                                                         style={{ width: chic.width,
                                                             height: chic.height,
                                                             opacity: chic.opacity,
                                                             filter: `blur(${chic.blur})`,
                                                             backgroundSize: 'contain',
                                                             backgroundRepeat: 'no-repeat',
                                                             backgroundImage: `url(${chic.image})`,
                                                             transitionDuration: '1s'
                                                         }}>
                                                    </Box>
                                                )
                                            })}    
                                        </Box>
                                    </Box>
                                )
                            })}
                            
                            <Box position="absolute"
                                left={'calc(50% - 150px)'}
                                top={'calc(50% - 250px)'}
                                zIndex={10}
                                ref={this.eggRef}
                                style={{ width: 300, 
                                        height: 300,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        backgroundImage: `url(${require(`@assets/exercise/memoryEgg/ch1.png`)})`,
                                        }}>
                            </Box>  
                        </Box>
                        
                        <Box
                            display={this.isReverse ? 'flex' : 'none'}
                            justifyContent="center"
                            position="absolute"
                            top={'calc(50% - 280px)'}
                            style={{height: 100, width:'100%', zIndex: 20}}>
                                 <Grid container spacing={1} justify={"center"}>
                                    <Grid item>
                                        <Box style={{ 
                                            width: 120, 
                                            height: 100,
                                            borderRadius: 10,
                                            backgroundImage: `url(${require(`@assets/exercise/memory/reverseIcon.png`)})`,
                                            backgroundSize: '50%',
                                            backgroundColor: 'rgba(0,0,0,0.7)',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center'}}>
                                        </Box>
                                    </Grid>
                                </Grid>
                        </Box>


                        <Box
                            display="flex"
                            justifyContent="center"
                            position="absolute"
                            top={'calc(50% - 175px)'}
                            style={{
                                width:'100%', 
                                zIndex: 20,
                                transitionDuration: 0.5,
                                opacity: this.state.showAnswerBox ? 1 : 0,
                            }}
                        >
                            <Grid container spacing={1} justify={"center"} style={{width: '100%'}}>
                                {imEggCount.map((pos, index) => {
                                    let image
                                    const selectedIndex = this.selectedEggs[index]
                                    const correctIndex = imCurrentEggs[index] 
                                    
                                    if(null != selectedIndex){
                                        image = require(`@assets/exercise/memoryEgg/${selectedIndex + 1}.png`)
                                    } else {
                                        image = require(`@assets/exercise/memoryEgg/answer.png`)
                                    }

                                    let grayscale = '0%';
                                    if(selectedIndex !== correctIndex){
                                        grayscale = '100%'
                                    }
                                    return (
                                        <Grid item key={index}>
                                            <Box 
                                                style={{ width: 170, 
                                                        height: 175,
                                                        borderRadius: 10,
                                                        backgroundImage: `url(${image})`,
                                                        backgroundSize: 'contain',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center',
                                                        filter: `grayscale(${grayscale})`,
                                                        backgroundColor: 'rgba(244,255,10,0.3)'}}>
                                            </Box>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            position="absolute"
                            bottom={20}
                            style={{width:'100%', zIndex: 20}}>
                            <Grid container spacing={1} justify={"center"}  style={{width:'100%'}}>
                                {this.state.items.map((pos, index) => {
                                    const image = require(`@assets/exercise/memoryEgg/${index + 1}.png`)
                                    const eggCorrectNumber = this.currentEggs.indexOf(index)
                                    return (
                                        <Grid item key={index}>
                                            <Box onClick={()=>{
                                                this.eggClick(index)
                                            }} 
                                                style={{ 
                                                    width: 120, 
                                                    height: 125,
                                                    borderRadius: 10,
                                                    cursor: 'pointer',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundImage: `url(${image})`,
                                                    backgroundPosition: 'center',
                                                    transitionDuration: 0.5,
                                                    backgroundColor: 'rgba(244,255,10,0.9)'}}>

                                                { (-1  < eggCorrectNumber && AuthProvider.isSuperAdmin()) &&
                                                <Box style={{
                                                    backgroundColor: '#ddd',
                                                    width: 20,
                                                    height: 20,
                                                    textAlign: 'center'
                                                }}
                                                >
                                                    {eggCorrectNumber}
                                                </Box>
                                                }
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <AudioPlayer ref={ this.audioRef } 
                             mode={'balance'}
                             onAudioEnded={this.onAudioEnded.bind(this)}
                />
                <LevelDialog ref={this.levelDialogRef} 
                             onStart={this.startExercise.bind(this)}/>

                <RewardDialog ref={this.rewardDialogRef} />
                <CompleteDialog ref={this.completeDialogRef} history={this.state.history}/>
            </Box>
        )
    }
}


class Score extends  React.Component {

    defaultOptions = {
        currentScore: 0 ,
        maxScore: 100,
        maxCount: 9,
        currentCount: 1
    }
    constructor() {
        super();
        this.state = this.defaultOptions
    }

    getCount(){
        return this.state.currentCount;
    }

    isOver(){
        return this.state.currentCount >= this.state.maxCount
    }
    
    getScore() {
        return this.state.currentScore
    }

    reset() {
        this.setState({
            ...this.defaultOptions
        })
    }
    update(score, withCount = true) {
        this.setState({
            ...this.state,
            currentScore: this.state.currentScore + (score ? score: 0),
            currentCount: this.state.currentCount + (withCount ? 1 : 0)
        })
    }

    render() {
        const { currentScore, currentCount, maxCount} = this.state
        return (
            <div className={'score'}>
                <Hexagon
                    style={{
                        fill: 'rgba(0,0,0,0.7)',
                        stroke: '#fff',
                        strokeWidth:5,
                        transitionDuration: 0.5
                    }} className="text">
                    <text x="50%" y="40%" style={{fontSize: 80, fontWeight: 'normal'}}>{currentCount} / {maxCount}</text>
                    <text x="50%" y="65%">
                        {currentScore}
                    </text>
                </Hexagon>
            </div>
        )
    }
}


export default MemoryEgg
